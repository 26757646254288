import React from 'react';
import { useTranslation } from 'react-i18next';
import { A11y, Scrollbar, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
import 'moment/locale/fr';
import jambo from '../../assets/jambotv.PNG';
import pad from '../../assets/1x/page.jpg';
import actual from '../../assets/actual1.jpg';
import actual2 from '../../assets/actu2.avif';
import actual3 from '../../assets/actual2.jpg';
import { NavLink } from 'react-router-dom';
// import actual2 from '../../assets/actu2.avif';
// import actual3 from '../../assets/actual2.jpg';
import actual4 from '../../assets/programmes/actual.jpeg';
import actual5 from '../../assets/programmes/actual2.jpeg';
import actual6 from '../../assets/programmes/actual3.jpeg';
import actual7 from '../../assets/programmes/actual4.jpeg';
import actual8 from '../../assets/programmes/actual5.jpeg';
import actual9 from '../../assets/programmes/actual6.jpeg';
import actual10 from '../../assets/programmes/actual81.jpeg';

import actual12 from '../../assets/programmes/actual_canal2.png';
import actual13 from '../../assets/programmes/imgVideo1.png';
import actual14 from '../../assets/programmes/imgVideo2.png';
import actual15 from '../../assets/programmes/imgVideo3.png';
import actual16 from '../../assets/programmes/signature.png';
import { ArrowRight01Icon, ArrowRight02Icon } from 'hugeicons-react';

export function timeRegular(diffDays,postDate,t){
  if(diffDays < 7){
    return t('publish')+' '+postDate.fromNow()
  }else{
    return t('published_on')+' '+postDate.format("DD MMMM YYYY")
  }
}
function Services() {
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language); // Set moment locale based on selected language

  const data = [
    {
      id: 18,
      image: actual16,
      type: 'interne',
      libelle: "𝐒𝐢𝐠𝐧𝐚𝐭𝐮𝐫𝐞 𝐎𝐟𝐟𝐢𝐜𝐢𝐞𝐥𝐥𝐞 𝐃𝐮 𝐏𝐚𝐫𝐭𝐞𝐧𝐚𝐫𝐢𝐚𝐭 𝐂𝐜𝐜𝐚 & 𝐓𝐮𝐫𝐜𝐚𝐛𝐚 : 𝐔𝐧𝐞 𝐀𝐥𝐥𝐢𝐚𝐧𝐜𝐞 𝐒𝐭𝐫𝐚𝐭é𝐠𝐢𝐪𝐮𝐞 𝐏𝐨𝐮𝐫 𝐋’𝐚𝐯𝐞𝐧𝐢𝐫 𝐃𝐮 𝐂𝐨𝐦𝐦𝐞𝐫𝐜𝐞 𝐂𝐚𝐧𝐚𝐝𝐚-𝐭𝐮𝐫𝐪𝐮𝐢𝐞-𝐚𝐟𝐫𝐢𝐪𝐮𝐞 🇨🇦🇹🇷🇨🇲",
      descrip: "Le 3 février 2025, à Yaoundé, le Dr. Armand Ngaketcha Njafang, Président de la Chambre de Commerce Canada-Afrique (CCCA), et M. Fabrice Funiba, Président de la Chambre de Commerce Turco-Camerounaise (TURCABA) ...",
      date: '2025-02-05T08:00:00Z',
  }, 
    {
      id: 17,
      image: actual15,
      type: 'interne',
      libelle: "ÉXONÉRATIONS DOUANIÈRES",
      descrip: "<iframe width='887' height='499' src='https://www.youtube.com/embed/G4s7aVRLUjQ' title='L&#39;INVITE DE LA SEMAINE: Armand NGAKETCHA NJAFANG,Président de la chambre de commerce Canada/Afrique' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe>",
      date: '2025-02-04T12:00:00Z',
  }, 
  {
      id: 16,
      image: actual14,
      type: 'interne',
      libelle: "ÉNONCÉ DE POLITIQUE GÉNÉRALE DE LA CCCA",
      descrip: "<iframe width='887' height='499' src='https://www.youtube.com/embed/G4s7aVRLUjQ' title='L&#39;INVITE DE LA SEMAINE: Armand NGAKETCHA NJAFANG,Président de la chambre de commerce Canada/Afrique' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe>",
      date: '2025-02-04T12:00:00Z',
  }, 
  {
      id: 15,
      image: actual13,
      type: 'interne',
      libelle: "BÂTIR NOS RELATIONS D'AFFAIRES SUR LE COMMERCE ET L'INVESTISSEMENT",
      descrip: "<iframe width='887' height='499' src='https://www.youtube.com/embed/G4s7aVRLUjQ' title='L&#39;INVITE DE LA SEMAINE: Armand NGAKETCHA NJAFANG,Président de la chambre de commerce Canada/Afrique' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe>",
      date: '2025-02-04T12:00:00Z',
  }, 
  {
      id: 14,
      image: actual12,
      type: 'interne',
      libelle: "Interview du Président de la Chambre de Commerce Canada Afrique sur l'émission *L'Invité De La Semaine* de Canal2",
      descrip: "<iframe width='887' height='499' src='https://www.youtube.com/embed/G4s7aVRLUjQ' title='L&#39;INVITE DE LA SEMAINE: Armand NGAKETCHA NJAFANG,Président de la chambre de commerce Canada/Afrique' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' referrerpolicy='strict-origin-when-cross-origin' allowfullscreen></iframe>",
      date: '2025-02-04T12:00:00Z',
  }, 
    {
      id: 12,
      image: actual10,
      type: 'interne',
      libelle: "𝐕𝐞𝐫𝐬 𝐔𝐧𝐞 𝐂𝐨𝐥𝐥𝐚𝐛𝐨𝐫𝐚𝐭𝐢𝐨𝐧 𝐒𝐭𝐫𝐚𝐭é𝐠𝐢𝐪𝐮𝐞 𝐄𝐧 𝐑𝐝𝐜 🇨🇩 𝐗 🌍",
      descrip: "𝐔𝐧 𝐏𝐚𝐬 𝐃𝐞 𝐏𝐥𝐮𝐬 𝐃𝐚𝐧𝐬 𝐋𝐞 𝐑𝐞𝐧𝐟𝐨𝐫𝐜𝐞𝐦𝐞𝐧𝐭 𝐃𝐞𝐬 𝐑𝐞𝐥𝐚𝐭𝐢𝐨𝐧𝐬 É𝐜𝐨𝐧𝐨𝐦𝐢𝐪𝐮𝐞𝐬 !. La 𝐂𝐡𝐚𝐦𝐛𝐫𝐞 𝐃𝐞 𝐂𝐨𝐦𝐦𝐞𝐫𝐜𝐞 𝐂𝐚𝐧𝐚𝐝𝐚-𝐚𝐟𝐫𝐢𝐪𝐮𝐞 (CCCA) poursuit son déploiement en 𝐑é𝐩𝐮𝐛𝐥𝐢𝐪𝐮𝐞 𝐃é𝐦𝐨𝐜𝐫𝐚𝐭𝐢𝐪𝐮𝐞 𝐃𝐮 𝐂𝐨𝐧𝐠𝐨avec des avancées significatives. La semaine dernière, notre 𝐃é𝐥é𝐠𝐮é 𝐏𝐨𝐮𝐫 𝐋’𝐨𝐮𝐞𝐬𝐭 𝐃𝐞 𝐋𝐚 𝐑𝐝𝐜, 𝐋𝐚𝐮𝐫𝐞𝐧𝐭 𝐋𝐨𝐤𝐰𝐚 été reçu par la 𝐂𝐡𝐚𝐦𝐛𝐫𝐞 𝐃𝐞 𝐂𝐨𝐦𝐦𝐞𝐫𝐜𝐞 𝐈𝐧𝐭𝐞𝐫𝐧𝐚𝐭𝐢𝐨𝐧𝐚𝐥𝐞 𝐃𝐞 𝐋𝐚 𝐑𝐝𝐜 pour poser les bases d'une collaboration stratégique.",
      date: '2025-02-03T09:00:00Z',
  },     
    {
      id: 11,
      image: actual9,
      type: 'interne',
      libelle: "𝙐𝙣 𝙥𝙖𝙧𝙩𝙚𝙣𝙖𝙧𝙞𝙖𝙩 𝙚𝙣 𝙘𝙤𝙣𝙨𝙩𝙧𝙪𝙘𝙩𝙞𝙤𝙣 𝙚𝙣𝙩𝙧𝙚 𝙡𝙖 𝘾𝙝𝙖𝙢𝙗𝙧𝙚 𝙙𝙚 𝘾𝙤𝙢𝙢𝙚𝙧𝙘𝙚 𝘾𝙖𝙣𝙖𝙙𝙖-𝘼𝙛𝙧𝙞𝙦𝙪𝙚 (𝘾𝘾𝘾𝘼) 𝙚𝙩 𝙡𝙖 𝘾𝙝𝙖𝙢𝙗𝙧𝙚 𝙙𝙚 𝘾𝙤𝙢𝙢𝙚𝙧𝙘𝙚 𝘾𝙖𝙢𝙚𝙧𝙤𝙪𝙣𝙤-𝙏𝙪𝙧𝙦𝙪𝙚 (𝙏𝙐𝙍𝘾𝘼𝘽𝘼)",
      descrip: "Hier, Dr. Armand NGAKETCHA NJAFANG président de la CCCA, a rencontré Fabrice FUNIBA président de la TURCABA pour une première session d’échanges fructueux. Cette rencontre a permis d’explorer plusieurs axes de collaboration visant à renforcer les liens...",
      date: '2025-01-26',
  },    
    {
      image: actual8,
      type: 'interne',
      libelle: "𝗖𝗿é𝗲𝗿 𝗱𝗲𝘀 𝗹𝗶𝗲𝗻𝘀 𝘀𝗼𝗹𝗶𝗱𝗲𝘀 𝗱𝗮𝗻𝘀 𝗹𝗲 𝗱𝗼𝗺𝗮𝗶𝗻𝗲 𝗱𝗲 𝗹’é𝗱𝘂𝗰𝗮𝘁𝗶𝗼𝗻 𝗲𝘁 𝗹𝗮 𝗳𝗼𝗿𝗺𝗮𝘁𝗶𝗼𝗻 𝗰𝗼𝗻𝘁𝗶𝗻𝘂𝗲 𝗲𝗻𝘁𝗿𝗲 𝗹𝗲 𝗖𝗮𝗻𝗮𝗱𝗮 𝗲𝘁 𝗹𝗲 𝗖𝗮𝗺𝗲𝗿𝗼𝘂𝗻. 🌍🤝🇨🇲🇨🇦",
      descrip: "Notre Président Dr. Armand NGAKETCHA NJAFANG appuie la mission au Cameroun du Collège Boréal dans son développement d’affaires dans le secteur de l’éducation et la formation continue. Les membres du 𝗖𝗼𝗹𝗹è𝗴𝗲 𝗕𝗼𝗿é𝗮𝗹, acteur majeur de l'éducation francophone au Canada, ont eu l'opportunité de rencontrer des personnalités influentes et des institutions de renom au Cameroun.",
      date: '2025-01-26',
  },
  {
      image: actual7,
      type: 'interne',
      libelle: "𝗨𝗻𝗲 𝘀𝗼𝗶𝗿é𝗲 𝗱'𝗲𝗰𝗵𝗮𝗻𝗴𝗲𝘀 𝗲𝗻 𝗽𝗿é𝗹𝘂𝗱𝗲 𝗮𝘂 𝗽𝗿𝗼𝗷𝗲𝘁 𝗱'𝗲𝘅𝗼𝗻é𝗿𝗮𝘁𝗶𝗼𝗻 𝗱𝗲𝘀 𝗱𝗿𝗼𝗶𝘁𝘀 𝗱𝗲 𝗱𝗼𝘂𝗮𝗻𝗲 𝗱𝗲𝘀 𝗽𝗿𝗼𝗱𝘂𝗶𝘁𝘀 𝗰𝗮𝗻𝗮𝗱𝗶𝗲𝗻𝘀 🌎🫱🏽‍🫲🏽",
      descrip: "Invité par la 𝗗𝗼𝘂𝗮𝗻𝗲 𝗖𝗮𝗺𝗲𝗿𝗼𝘂𝗻𝗮𝗶𝘀𝗲, 𝗹𝗲 président de la 𝗖𝗵𝗮𝗺𝗯𝗿𝗲 𝗱𝗲 𝗖𝗼𝗺𝗺𝗲𝗿𝗰𝗲 𝗖𝗮𝗻𝗮𝗱𝗮-𝗔𝗳𝗿𝗶𝗾𝘂𝗲 (𝗖𝗖𝗖𝗔) Dr. Armand NGAKETCHA NJAFANG a participé hier à 𝗹𝗮 𝗦𝗼𝗶𝗿é𝗲 𝗱𝗲𝘀 𝗣𝗮𝗿𝘁𝗲𝗻𝗮𝗶𝗿𝗲𝘀 à l'hôtel là Falaise de Bonanjo.",
      date: '2025-01-25',
  },
  {
      id: 6,
      image: actual4,
      type: 'interne',
      libelle: "𝗥𝗲𝗻𝗳𝗼𝗿𝗰𝗲𝗿 𝗹𝗲𝘀 𝗹𝗶𝗲𝗻𝘀 𝗲𝗻𝘁𝗿𝗲 𝗹𝗮 𝗳𝗿𝗮𝗻𝗰𝗼𝗽𝗵𝗼𝗻𝗶𝗲 𝗲𝘁 𝗹𝗲𝘀 𝗮𝗳𝗳𝗮𝗶𝗿𝗲𝘀 𝗲𝗻 𝗥𝗲́𝗽𝘂𝗯𝗹𝗶𝗾𝘂𝗲 𝗗𝗲́𝗺𝗼𝗰𝗿𝗮𝘁𝗶𝗾𝘂𝗲 𝗱𝘂 𝗖𝗼𝗻𝗴𝗼-𝗘𝘀𝘁 🌍🇨🇦🇨🇩",
      descrip: "M. charles mulanda, délégué pays de la Chambre de Commerce Canada Afrique en 𝗥𝗗𝗖-𝗘𝘀𝘁, a eu l'honneur de participer à une rencontre d'affaires enrichissante avec M. Léandre MIEMA, Délégué Général Permanent de la Francophonie et Correspondant National auprès de l'OIF/RDC.",
      date: '2025-01-22',
  },
  {
      image: actual6,
      type: 'interne',
      libelle: "𝐑𝐞𝐧𝐟𝐨𝐫𝐜𝐞𝐦𝐞𝐧𝐭 𝐝𝐞𝐬 𝐫𝐞𝐥𝐚𝐭𝐢𝐨𝐧𝐬 𝐛𝐢𝐥𝐚𝐭é𝐫𝐚𝐥𝐞𝐬 𝐞𝐧𝐭𝐫𝐞 𝐥’𝐀𝐥𝐠é𝐫𝐢𝐞 𝐞𝐭 𝐥𝐞 𝐂𝐚𝐧𝐚𝐝𝐚 🌍",
      descrip: "Deux audiences marquantes ont eu lieu dans le cadre du développement des relations économiques entre l’Algérie et le Canada :1️⃣ 𝗔𝘂𝗱𝗶𝗲𝗻𝗰𝗲 𝗮𝘂 𝗺𝗶𝗻𝗶𝘀𝘁è𝗿𝗲 𝗱𝗲𝘀 𝗔𝗳𝗳𝗮𝗶𝗿𝗲𝘀 é𝘁𝗿𝗮𝗻𝗴è𝗿𝗲𝘀, 👉 𝐌. 𝐌𝐨𝐡𝐚𝐧𝐝 𝐎𝐮𝐫𝐚𝐦𝐝𝐚𝐧𝐞 𝐋𝐀𝐑𝐀𝐁, délégué de l'Algérie auprès de la Chambre de Commerce Canada-Afrique (CCCA), a échangé avec 𝐌. 𝐑𝐚𝐛𝐚𝐡 𝐅𝐚𝐬𝐢𝐡, Directeur de la Coopération et des Échanges Économiques.",
      date: '2025-01-18'
  },
  {
      image: actual5,
      type: 'interne',
      libelle: "Un partenariat prometteur avec l’Agence Guinéenne de Promotion des Exportations (AGUIPEX)",
      descrip:"Ce jeudi 16 janvier 2025 à 18h30, Mr Robin Camara, Représentant Pays de la Chambre de Commerce Canada Afrique et son assistante Mme 𝗗𝗶𝗲𝗻𝗴 𝗦𝗲𝗹𝗹𝘆 𝗦𝗲𝗰𝗸, ont été reçus par M. Amadou D’affaires Baldé, Directeur Général de l' Agence Guinéenne de ...",
      date: '2025-01-18',
  },                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    {
      type:'interne',
      image: jambo, 
      libelle: t('news_title1'),
      date: moment('2024-07-23').format('LL'), // Format date in localized format
      link: '/a/Actualites/1',
    },
    {
      image: actual,
      type: 'externe',
      libelle: t('brain_drain_africa'),
      date: moment('2024-10-09').format('LL'),
      link: 'https://francopresse.ca/actualite/2024/10/07/exode-des-cerveaux-lafrique-se-vide-au-profit-du-canada/',
    },
    {
      image: actual2,
      type: 'externe',
      libelle: t('quebec_recruitment'),
      date: moment('2024-10-02').format('LL'),
      link: 'https://ici.radio-canada.ca/nouvelle/2108988/recruter-infirmieres-afrique-ethique-caq-maroc-tunisie',
    },
    {
      image: actual3,
      libelle: t('green_hydrogen_opportunities'),
      date: moment('2024-09-10').format('LL'),
      link: 'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene',
    },
  ];


  const SlideItems = ({ item }) => (
    <a href={item?.type !== 'interne' ? item.link :'/a/Actualites/'+item.libelle} className="flex relative flex-col items-center justify-start gap-2 bg-white overflow-hidden">
      <div className='relative w-[100%] h-[250px]'>
        <img src={item.image} className={`absolute ${(item.id===15 || item.id===16 ||item.id===17)? 'object-contain':'object-cover'} ${item.id===6 && 'object-top'} w-full h-full`} alt='~#' />
      </div>
      <div className='flex w-full flex-col justify-start gap-3 px-5 py-2 pb-2 h-[140px] max-lg:h-[160px] max-md:h-[130px]'>
        <h1 className='font-bold text-primary hover:text-secondary text-[1.15rem] overflow-hidden text-ellipsis max-md:text-[1.05rem] max-sm:text-[.9rem]'>
          {item?.libelle?.length>200 ? item.libelle.slice(0,200)+' ...':item.libelle}
        </h1>
        <span className='font-medium absolute bottom-4 capitalize text-gray-500 text-[.85rem] max-md:text-[.85rem] max-sm:text-[.7rem]'>
           {timeRegular(moment().diff(item.date, "days"),moment(item.date),t)}
        </span>
      </div>
    </a>
  );

  return (
    <div className='bg-secondary px-[5%] py-14 w-full'>
      <div className='flex justify-between items-center mb-4'>
        <h1 className='text-white text-[2.1rem] max-md:text-[2rem] font-semibold'>{t('news')}</h1>
        <a className='flex items-center max-md:text-[.85rem] gap-2 text-white font-medium' href='/a/Actualites'>{t('readMore')} <ArrowRight02Icon /></a>
      </div>
      <div className=''>
        <Swiper
          modules={[Scrollbar, Autoplay, A11y]}
          autoplay={{
            delay: 9500,
            disableOnInteraction: false,
            pauseOnMouseEnter:true,   
          }}
          loop={true}
          breakpoints={{
            300: { slidesPerView: 1, spaceBetween: 15 },
            640: { slidesPerView: 1, spaceBetween: 15 },
            730: { slidesPerView: 2, spaceBetween: 20 },
            1024: { slidesPerView: 3, spaceBetween: 30 },
          }}
          className="mySwipe"
          scrollbar={{ draggable: true }}
        >
          {data?.slice(0,6)?.map((x, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <SlideItems item={x} />
            </SwiperSlide>
          ))}
        </Swiper> 
      </div>
    </div>
  );
}

export default Services;
